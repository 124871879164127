@font-face {
  font-family: 'Marigny';
  src: url(./assets/fonts/Marigny-Medium.woff2) format('woff2');
}

@font-face {
  font-family: IBM Plex Sans,sans-serif;
  src: url(./assets/fonts/IBMPlexSans-Regular.otf) format('otf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url(./assets/fonts/Poppins-Black.ttf) format('tff');
}
@font-face {
  font-family: Helvetica45;
  src: url(./assets/fonts/Helvetica45.ttf) format('tff');
}
@font-face {
  font-family: TiltWarp;
  src: url(./assets/fonts/TiltWarp-Regular.ttf) format('tff');
}




:root {
  --primary-background-color: 'white'
}
:root {
  --button-color: 'transparent'
}

:root {
  --button-border-color: 'transparent'
}

:root {
  --button-text-color: '#ffff'
}

:root {
  --font-color: 'transparent'
}
:root {
  --font-family: Helvetica, "Open Sans", Arial, sans-serif;
}
:root {
  --status-color: '#ffff'
}

html {
  height: 100%;
}
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: var(--primary-background-color);
  font-family:var(--font-family);
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.container {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-height: 706px;
  height: 100%;
  left: 0;

}

.questionContainer {
  position: relative;
  width: 100%;
  top: 97px;
  background: #FFFFFF;
  border-radius: 20px 20px 0 0;
  justify-content: center;
  padding-top: 10px;
  min-height: calc(100% - 97px);
}

.container-decoration-b {
  position: relative;
  width: 90%;
  max-width: 450px;
  height: 15px;
  margin-left: 5%;
  border-radius: 20px 20px 0 0;
  background-color: rgba(255, 255, 255, 0.5);
  top: 98px;
}
.container-decoration-a {
  position: absolute;
  width: 80%;
  max-width: 400px;
  height: 15px;
  margin-left: 10%;
  border-radius: 20px 20px 0 0;
  background-color: rgba(255, 255, 255, 0.3);
  top: 83px;
}

h1 {
  font-weight: 400;
  font-size: 1.625rem;
  font-family:var(--font-family);
  color: var(--font-color);
  padding-left: 10px;
  padding-right: 10px;
}

h2 {
  font-weight: 400;
  font-size: 1.25rem;
  font-family: var(--font-family);
  color: var(--font-color);
}

select {
  font-size: 20px;
  font-family: var(--font-family);
}

.optionsBox, select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: start;

  width: 90%;
  margin-left: 5%;
  min-height: 62px;
  background: #FFFFFF;
  border: 2px solid #E1E5EA;
  border-radius: 12px;
  margin-bottom: 10px;
}
.optionsBox p {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 20px;
  max-width: 300px;
  padding-left: 5px;
  align-items: center;
  display: flex;
}
.optionsBoxInput {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.optionsBoxText {
  height: 30px;
  margin-right: 10px;
  display: flex;
  flex: 1;
  border: none;
  border-bottom: grey 1px solid;
}

.textInput {
  width: 90%;
  border-radius: 5px;
  height: fit-content;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  font-family: var(--font-family);
}

.header-back {
  position: absolute;
  width: 100%;
  max-width: 500px;
  height: 50px;
  text-align: start;
  top: 10px;
  left: 20px;
  font-family: var(--font-family);
  align-items: center;
  display: flex;
  color: var(--button-color);
}

.progress-status {
  position: absolute;
  width: 90%;
  margin-left: 5%;
  max-width: 500px;
  height: 100px;
  text-align: center;
  color: var(--status-color);
}

.progress-bar-container {
  position: relative;
  width: 100%;
  height: 10px;
  text-align: center;
  background-color: #FFFFFF;
}
.progress-bar-bar {
  height: 10px;
  width: 50%;
  position: relative;
  background-color: var(--button-color);
}
.footer {
  height: 10%;
}
.button-fixed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--button-color);
  color: var(--button-text-color);
  border-radius: 50px;
  width: 80%;
  margin-left: 10%;
  position: relative;
}
.button-fixed p {
  display: flex;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 18px;
}

.button-float {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--button-color);
  color: var(--button-text-color);
  border-radius: 50px;
  width: 80%;
  margin-left: 10%;
  position: relative;
}
.button-float p {
  display: flex;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 18px;
}



#welcome .header {
  margin-top: 20px;
  margin-bottom: 0;
}

#welcome .container-text {
  background: #FFFFFF;
  border-radius: 20px;
  justify-content: center;
  padding: 40px 20px;
  font-family: var(--font-family);
  margin-top: -5px;
}

#welcome .alpaca-btn {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 16px 32px;
  border-radius: 50px;
  border-style: solid;
  border-color: var(--button-border-color, var(--button-color));
  background-color: var(--button-color);
  font-family: var(--font-family);
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}

#welcome .alpaca-link {
  margin-top: 2.5em;
}

#welcome .container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 100px;
}

#wordcloud .flex-container {
  display: flex;
  justify-content: center;
  padding-bottom: 45px;
  flex-wrap: wrap;
}
#wordcloud .flex-container > div {
  height: 50px;
  width: 50px;
  padding: 10px;
  margin: 17px;
  line-height: 25px;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,198,41, 0.2);
  color: var(--font-color);
  font-size: 14px;
}
#wordcloud a {
  text-decoration: underline;
}

#wordcloud .button-fixed {
  margin-top: 40px;
}

.goodbye h1 {
  margin-top: 75px;
}
.goodbye h2 {
  margin-top: 75px;
  width: 350px;
  margin-left: calc(50% - 175px);
  line-height: 32px;
  font-size: 20px;
}
.goodbye .logo {
  position: relative;
  margin-top: 75px;
}
.goodbye .squiggle {
  position: relative;
  margin-top: 75px;
}

.info {
  color: var(--font-color);
  font-style: italic;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 400;
}

.displayPackModal {
  position: absolute;
  width: 100%;
  max-width: 500px;
  height: 500px;
  top: calc(50% - 250px);
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.displayPackModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  align-items: center;
}
.displayPackModalContent img {
  height: auto;
  width: 300px;
}
.displayPackModalContent p {
  color: var(--font-color);
  font-family: 'IBM Plex Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.closeModal {
  display: flex;
  position: relative;
  top: 20px;
  justify-content: flex-end;
  width: 100%;
  font-size: 30px;
}

.clickable {
  cursor: pointer;
}


.powered-by {
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 75px;
  font-size: 16px;
  position: absolute;
  bottom: 0;
}

.powered-by-float {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 75px;
  font-size: 16px;
}

.powered-by-float img {
  height: 40px;
  margin-left: 10px;
}

.powered-by img {
  height: 40px;
  margin-left: 10px;
}

.alpaca-link {
  text-decoration: underline;
  font-size: 0.875rem;
  color: #0a2240;
  background: transparent;
  border: none;
}

#question-categories .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
#question-categories .col {
  display: flex;
  width: 33.33333%;
  justify-content: center;
  align-items: flex-start;
}
#question-categories .category {
  height: 120px;
  width: 100px;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}
#question-categories .category p{
  font-family: var(--font-family);
  margin-top: 5px;
}
.category-icon {
  height: 80px;
  width: 80px;
  background-color: transparent;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.demographicsOptionsBox {
  display: flex;
  gap: 10px;
  text-align: start;
  width: 90%;
  margin-left: 5%;
  min-height: 62px;
  background: #FFFFFF;
  border: 2px solid #E1E5EA;
  border-radius: 12px;
  margin-bottom: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.demographicsOption {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.demographicsOption p {
  display: flex;
}
.demographicsOption input {
  display: flex;
  height: 20px;
  width: 20px;
}

.demographicsOptionContainer {
  display: flex;
  justify-content: space-evenly; /* distribute columns evenly */
  width: 100%;
  flex-direction: row; /* set the flex direction to row */
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.demographicsOptionContainer .column {
  width: 30%; /* set each column to 30% of container width */
}

.demographicsOptionContainer .is-selected {
  border-color: #282c34 !important;
}
.demographicsOptionContainer .radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25%;
  margin-bottom: 10px;
  border: 2px solid #ececec;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
}

.demographicsOptionsBox h2 {
  margin: 5px;
  font-size: 1.25em;
  font-weight: 700;
  text-transform: capitalize;
}
.demographicsOptionsBox p {
  margin: 0;
}




